export enum STATUS_TYPE {
  error = 'error',
  info = 'info'
}

/**
 * This is equivalent to:
 * type LogLevelStrings = 'error' | 'info';
 */
export type statusStrings = keyof typeof STATUS_TYPE

export enum ORDER_TYPE {
  created = 'created',
  pending = 'pending',
  approved = 'approved',
  confirmed = 'confirmed',
  disbursed = 'disbursed',
  cancelled = 'cancelled'
}

export enum DOCUMENT_TYPE {
  hkid = 'hkid_proof',
  bankAccount = 'bank_account',
  kba = 'kba'
}

export enum GUIDELINE_TYPE {
  correct = 'correct',
  wrong = 'wrong'
}

export enum LOAN_FORM_PATH {
  home = '/',
  loanInformation = '/form/loan-information',
  personalInformation = '/form/personal-information',
  uploadToken = '/verify',
  residentialInformation = '/form/residential-information',
  employmentInformation = '/form/employment-information',
  review = '/form/review',
  aip = '/offer/aip',
  selfie = '/idv/selfie',
  agreement = '/offer/agreement',
  otp = '/offer/sign-otp',
  upload = '/idv/doc',
  paymentAccount = '/idv/payment-account',
  additionalInformation = '/verify/additional-information',
  hkid = '/verify/hkid',
  kba = '/idv/kba',
  success = '/success',
  generalReject = '/general-reject',
  error = '/error',
  loading = '/loading',
  hkidQRCode = '/verify/token',
  warning = '/warning'
}

export const WARNING_ROUTES = [
  LOAN_FORM_PATH.personalInformation,
  LOAN_FORM_PATH.residentialInformation,
  LOAN_FORM_PATH.review,
  LOAN_FORM_PATH.agreement,
  LOAN_FORM_PATH.otp,
  LOAN_FORM_PATH.upload,
  LOAN_FORM_PATH.paymentAccount,
  LOAN_FORM_PATH.kba,
  LOAN_FORM_PATH.hkid
]

export enum USER_STATE {
  incomplete = 'incomplete',
  cancel = 'cancel',
  rejected = 'rejected',
  approved = 'approved'
}

export enum LOAN_STATE {
  incomplete = 'incomplete',
  applied = 'applied',
  tuDataUpdated = 'tu_data_updated',
  aip = 'aip',
  approved = 'approved',
  pendingDocUpload = 'pending_doc_upload',
  docUploaded = 'doc_uploaded',
  docVerified = 'doc_verified',
  agreementSigned = 'agreement_signed',
  rejected = 'rejected'
}
export enum LOAN_WORKFLOW {
  personalInformation = 'personal_information',
  review = 'review_and_confirm',
  residentialInformation = 'residential_information'
}

export enum OFFER_TYPE {
  rejectOffer = 'reject_offer',
  cashRebate = 'cash_rebate',
  physicalGift = 'physical_gift',
  interestDiscount = 'interest_discount'
}

export enum GTM_EVENT {
  customEvent = 'custom events'
}

export enum GTM_ACTION {
  formInput = 'form_input',
  cta = 'cta',
  verification = 'verification',
  fileDownload = 'file_download',
  accordion = 'accordion'
}

export enum FLOODLIGHT_LABEL {
  loanInformation = 'DC-5604620/appfl0/a01-l0+standard',
  personalInformation = 'DC-5604620/appfl0/a02-p0+standard',
  residentialInformation = 'DC-5604620/appfl0/a03-r0+standard',
  employmentInformation = 'DC-5604620/appfl0/a04-e0+standard',
  confirmInformation = 'DC-5604620/appfl0/a05-r0+standard',
  thankYouPage = 'DC-5604620/appfl0/a06-t0+unique',
  thankYouButton = 'DC-5604620/appfl0/a065-0+standard'
}

export enum ONBOARD_STATE {
  incomplete = 'incomplete',
  applied = 'applied',
  completed = 'completed', //Completed but not success
  aip = 'aip', //waiting
  succeeded = 'succeeded' //Completed with success
}

export enum STEP_NAME_KEY {
  createProfile = 'create_profile',
  otherInformation = 'other_information',
  reviewAndConfirm = 'review_and_confirm', //Review and confirm
  additionalInformation = 'additional_information', // Additional information
  identityVerification = 'identity_verification', //Identity verification
  paymentAccount = 'payment_account', //Payment account
  agreement = 'agreement', //Agreement
  wasteApplianceRemoval = 'waste appliance removal', //Waste appliance removal
  orderSummary = 'order_summary', //Order summary
  shippingAddress = 'shipping_address', //Order summary
  apply = 'apply',
  information = 'information',
  verify = 'verify',
  hkid = 'hkid',
  question = 'question'
}

export enum PROGRESS_TITLE_KEY {
  enrollment = 'enrollment',
  checkout = 'checkout'
}

export const PROGRESS_STEP_LIST = {
  form: [
    { name: STEP_NAME_KEY.createProfile, url: LOAN_FORM_PATH.personalInformation },
    { name: STEP_NAME_KEY.otherInformation, url: LOAN_FORM_PATH.residentialInformation },
    { name: STEP_NAME_KEY.reviewAndConfirm, url: LOAN_FORM_PATH.review }
  ],
  kba: [
    { name: STEP_NAME_KEY.identityVerification, url: LOAN_FORM_PATH.kba },
    { name: STEP_NAME_KEY.question, url: LOAN_FORM_PATH.kba },
    { name: STEP_NAME_KEY.question, url: LOAN_FORM_PATH.kba },
    { name: STEP_NAME_KEY.question, url: LOAN_FORM_PATH.kba },
    { name: STEP_NAME_KEY.question, url: LOAN_FORM_PATH.kba },
    { name: STEP_NAME_KEY.question, url: LOAN_FORM_PATH.kba }
  ],
  payment_account: [{ name: STEP_NAME_KEY.paymentAccount, url: LOAN_FORM_PATH.paymentAccount }],
  offer: [
    { name: STEP_NAME_KEY.shippingAddress },
    { name: STEP_NAME_KEY.wasteApplianceRemoval },
    { name: STEP_NAME_KEY.orderSummary },
    { name: STEP_NAME_KEY.agreement }
  ],
  verify: [
    { name: STEP_NAME_KEY.additionalInformation, url: LOAN_FORM_PATH.additionalInformation },
    { name: STEP_NAME_KEY.additionalInformation, url: LOAN_FORM_PATH.additionalInformation },
    { name: STEP_NAME_KEY.additionalInformation, url: LOAN_FORM_PATH.additionalInformation }
  ],
  hkid: [
    { name: STEP_NAME_KEY.hkid, url: LOAN_FORM_PATH.hkid },
    { name: STEP_NAME_KEY.hkid, url: LOAN_FORM_PATH.hkid }
  ]
}

export const API_ENDPOINT = {
  uploadProofs: '/v1/users/upload_proofs',
  uploadProofsViaQr: '/v1/users/upload_proofs_via_qr',
  docUploadQrToken: '/v1/users/doc_upload_qr_token',
  dropOffKba: '/v1/users/drop_off_kba',
  kbaQuestion: '/v1/users/kba_questions',
  startKba: '/v1/users/start_kba',
  submitKbaAnswers: '/v1/users/submit_kba_answers',
  options: '/v1/users/options',
  language: '/v1/users/language',
  profile: '/v1/users/onboarding/profile',
  workflow: '/v1/users/workflow',
  declarationVersion: '/v1/users/declaration_version',
  personalInfo: '/v1/users/personal_info',
  residentialInfo: '/v1/users/residential_info',
  triggerTencentsTest: '/v1/users/trigger_tencents_test',
  paymentDetails: '/v1/users/payment_details',
  apply: '/v1/users/apply',
  completeOnboard: '/v1/users/complete_onboard',
  createMerchantCreditLine: '/v1/users/credit_line'
}

export enum FAILURE_RESPONSE_CODE {
  R1 = 'R1',
  R2 = 'R2',
  R3 = 'R3',
  R4 = 'R4',
  R5 = 'R5',
  R6 = 'R6',
  R7 = 'R7',
  R8 = 'R8',
  R9 = 'R9',
  R10 = 'R10',
  R11 = 'R11',
  R12 = 'R12',
  R13 = 'R13'
}

export const TYPE_ONE_ERROR_CODE = [
  FAILURE_RESPONSE_CODE.R1,
  FAILURE_RESPONSE_CODE.R5,
  FAILURE_RESPONSE_CODE.R8,
  FAILURE_RESPONSE_CODE.R9,
  FAILURE_RESPONSE_CODE.R10
]

export const TYPE_TWO_ERROR_CODE = [
  FAILURE_RESPONSE_CODE.R2,
  FAILURE_RESPONSE_CODE.R3,
  FAILURE_RESPONSE_CODE.R4,
  FAILURE_RESPONSE_CODE.R6,
  FAILURE_RESPONSE_CODE.R7,
  FAILURE_RESPONSE_CODE.R11,
  FAILURE_RESPONSE_CODE.R12,
  FAILURE_RESPONSE_CODE.R13
]

export enum FAILURE_REASON_DISPLAY_CODE {
  R1 = 'CE0021',
  R2 = 'CE0022',
  R3 = 'CE0023',
  R4 = 'CE0024',
  R5 = 'CE0025',
  R6 = 'CE0026',
  R7 = 'CE0027',
  R8 = 'CE0028',
  R9 = 'CE0029',
  R10 = 'CE00210',
  R11 = 'CE00211',
  R12 = 'CE00212',
  R13 = 'CE00213'
}

export enum MERCHANT_CREDIT_LINE_STATE {
  existed = 'existed',
  completed = 'completed',
  notExisted = 'not_existed'
}

export enum MODAL_TYPE {
  contactUs = 'contactUs'
}
