import * as types from '@src/reducers/orderReducer'

import {
  Agreement,
  CompleteOnboard,
  CreateMerchantCreditLineAction,
  GetDeclarationVersion,
  GetDeclarationVersionFailureAction,
  GetDeclarationVersionSuccessAction,
  GetOrderAgreementAction,
  GetOrderAgreementSuccessAction,
  GetOrderInfoAction,
  GetOrderInfoSuccessAction,
  GetPersonalInfoAction,
  GetPersonalInfoSuccessAction,
  GetWorkFlowAction,
  GetWorkFlowSuccessAction,
  OrderInfo,
  PaymentBankInformation,
  PersonalInformation,
  PollWorkFlowAction,
  PollWorkFlowStopAction,
  ResidentialInformation,
  ReviewInformation,
  SetAgreementAudioListenedAction,
  SetAgreementAudioListenedFailureAction,
  SetAgreementAudioListenedSuccessAction,
  SetAgreementReadAction,
  SubmitOrderCheckoutAction,
  SubmitOrderCheckoutFailureAction,
  SubmitOrderCheckoutSuccessAction,
  TriggerSuccessRedirectAction,
  TriggerTencentsTestAction,
  TriggerTencentsTestFailureAction,
  TriggerTencentsTestSuccessAction,
  UpdateCompleteBoardAction,
  UpdateCompleteBoardFailureAction,
  UpdateCompleteBoardSuccessAction,
  UpdateHasAppliedAction,
  UpdateOrderErrorAction,
  UpdateOrderIdAction,
  UpdateOrderPersonalInfoAction,
  UpdateOrderPersonalInfoFailureAction,
  UpdateOrderPersonalInfoSuccessAction,
  UpdateOrderResidentialInfoAction,
  UpdateOrderResidentialInfoFailureAction,
  UpdateOrderResidentialInfoSuccessAction,
  UpdateOrderReviewInfoAction,
  UpdateOrderReviewInfoFailureAction,
  UpdateOrderReviewInfoSuccessAction,
  UpdatePaymentBankInfoAction,
  UpdatePaymentBankInfoSuccessAction,
  UpdateTuPulledWithin14DaysAction,
  UpdateTuPulledWithin14DaysSuccessAction,
  UserProfile,
  Workflow
} from '@src/types/store/order'

export const updateOrderPersonalInfo = (
  personalInformation: Partial<PersonalInformation>
): UpdateOrderPersonalInfoAction => ({
  type: types.UPDATE_ORDER_PERSONAL_INFO,
  payload: { personalInformation }
})

export const updateOrderPersonalInfoSuccess = (
  personalInformation: Partial<PersonalInformation>
): UpdateOrderPersonalInfoSuccessAction => ({
  type: types.UPDATE_ORDER_PERSONAL_INFO_SUCCESS,
  payload: { personalInformation }
})

export const updateOrderPersonalInfoFailure = (
  error: string
): UpdateOrderPersonalInfoFailureAction => ({
  type: types.UPDATE_ORDER_PERSONAL_INFO_FAILURE,
  payload: { error }
})

export const updateOrderResidentialInfo = (
  residentialInformation: ResidentialInformation
): UpdateOrderResidentialInfoAction => ({
  type: types.UPDATE_ORDER_RESIDENTIAL_INFO,
  payload: { residentialInformation }
})

export const updateOrderResidentialInfoSuccess = (
  residentialInformation: Partial<UserProfile>
): UpdateOrderResidentialInfoSuccessAction => ({
  type: types.UPDATE_ORDER_RESIDENTIAL_INFO_SUCCESS,
  payload: { residentialInformation }
})

export const updateOrderResidentialInfoFailure = (
  error: string
): UpdateOrderResidentialInfoFailureAction => ({
  type: types.UPDATE_ORDER_RESIDENTIAL_INFO_FAILURE,
  payload: { error }
})

export const updateOrderReviewInfo = (
  reviewInformation: ReviewInformation
): UpdateOrderReviewInfoAction => ({
  type: types.UPDATE_ORDER_REVIEW_INFO,
  payload: { reviewInformation }
})

export const updateOrderReviewInfoSuccess = (
  reviewInformation: Partial<ReviewInformation>,
  userProfile: Partial<UserProfile>
): UpdateOrderReviewInfoSuccessAction => ({
  type: types.UPDATE_ORDER_REVIEW_INFO_SUCCESS,
  payload: { reviewInformation, userProfile }
})

export const updateOrderReviewInfoFailure = (
  error: string
): UpdateOrderReviewInfoFailureAction => ({
  type: types.UPDATE_ORDER_REVIEW_INFO_FAILURE,
  payload: { error }
})

export const updatePaymentAccount = (
  paymentBankInformation: PaymentBankInformation
): UpdatePaymentBankInfoAction => ({
  type: types.UPDATE_ORDER_PAYMENT_ACCOUNT,
  payload: { paymentBankInformation }
})

export const updatePaymentAccountSuccess = (
  paymentBankInformation: Partial<UserProfile>
): UpdatePaymentBankInfoSuccessAction => ({
  type: types.UPDATE_ORDER_PAYMENT_ACCOUNT_SUCCESS,
  payload: { paymentBankInformation }
})

export const getWorkFlow = (): GetWorkFlowAction => ({
  type: types.GET_WORKFLOW
})
export const getWorkFlowSuccess = (workflow: Partial<Workflow>): GetWorkFlowSuccessAction => ({
  type: types.GET_WORKFLOW_SUCCESS,
  payload: { workflow }
})

export const getPersonalInfo = (): GetPersonalInfoAction => ({
  type: types.GET_PERSONAL_INFO
})

export const updateOrderId = (order: OrderInfo): UpdateOrderIdAction => ({
  type: types.UPDATE_ORDER_ID,
  payload: { order }
})

export const getOrderInfo = (orderId: string | undefined): GetOrderInfoAction => ({
  type: types.GET_ORDER_INFO,
  payload: { orderId }
})

export const getOrderInfoSuccess = (order: OrderInfo): GetOrderInfoSuccessAction => ({
  type: types.GET_ORDER_INFO_SUCCESS,
  payload: { order }
})

export const getPersonalInfoSuccess = (
  personalInformation: Partial<UserProfile>
): GetPersonalInfoSuccessAction => ({
  type: types.GET_PERSONAL_INFO_SUCCESS,
  payload: { personalInformation }
})

export const pollWorkFlow = (interval: number): PollWorkFlowAction => ({
  type: types.POLL_WORK_FLOW,
  payload: { interval }
})

export const pollWorkFlowStop = (): PollWorkFlowStopAction => ({
  type: types.POLL_WORK_FLOW_STOP
})

export const triggerTencentsTest = (): TriggerTencentsTestAction => ({
  type: types.TRIGGER_TENCENTS_TEST
})

export const triggerTencentsTestSuccess = (): TriggerTencentsTestSuccessAction => ({
  type: types.TRIGGER_TENCENTS_TEST_SUCCESS
})

export const triggerTencentsTestFailure = (): TriggerTencentsTestFailureAction => ({
  type: types.TRIGGER_TENCENTS_TEST_FAILURE
})

export const setAgreementAudioListened = (
  orderId: string | undefined
): SetAgreementAudioListenedAction => ({
  type: types.SET_AGREEMENT_AUDIO_LISTENED,
  payload: { orderId }
})

export const setAgreementAudioListenedSuccess = (): SetAgreementAudioListenedSuccessAction => ({
  type: types.SET_AGREEMENT_AUDIO_LISTENED_SUCCESS
})

export const setAgreementAudioListenedFailure = (): SetAgreementAudioListenedFailureAction => ({
  type: types.SET_AGREEMENT_AUDIO_LISTENED_FAILURE
})

export const getDeclarationVersion = (): GetDeclarationVersion => ({
  type: types.GET_DECLARATION_VERSION
})

export const UpdateCompleteBoard = (): UpdateCompleteBoardAction => ({
  type: types.UPDATE_COMPLETE_BOARD
})

export const UpdateCompleteBoardSuccess = (
  remaining: CompleteOnboard
): UpdateCompleteBoardSuccessAction => ({
  type: types.UPDATE_COMPLETE_BOARD_SUCCESS,
  payload: {
    remaining
  }
})

export const UpdateCompleteBoardFailure = (): UpdateCompleteBoardFailureAction => ({
  type: types.UPDATE_COMPLETE_BOARD_FAILURE
})

export const getDeclarationVersionSuccess = (
  version: string
): GetDeclarationVersionSuccessAction => ({
  type: types.GET_DECLARATION_VERSION_SUCCESS,
  payload: { version }
})

export const getDeclarationVersionFailure = (
  error: string
): GetDeclarationVersionFailureAction => ({
  type: types.GET_DECLARATION_VERSION_FAILURE,
  payload: { error }
})

export const getOrderAgreement = (orderId: string | undefined): GetOrderAgreementAction => ({
  type: types.GET_ORDER_AGREEMENT,
  payload: { orderId }
})

export const getOrderAgreementSuccess = (agreement: Agreement): GetOrderAgreementSuccessAction => ({
  type: types.GET_ORDER_AGREEMENT_SUCCESS,
  payload: { agreement }
})

export const setAgreementRead = (): SetAgreementReadAction => ({
  type: types.SET_AGREEMENT_READ
})

export const updateOrderError = (error: string): UpdateOrderErrorAction => ({
  type: types.UPDATE_ORDER_ERROR,
  payload: { error }
})

export const triggerSuccessRedirect = (): TriggerSuccessRedirectAction => ({
  type: types.SUCCESS_REDIRECT
})

export const submitOrderCheckout = (orderId: string): SubmitOrderCheckoutAction => ({
  type: types.SUBMIT_ORDER_CHECKOUT,
  payload: { orderId }
})

export const submitOrderCheckoutSuccess = (): SubmitOrderCheckoutSuccessAction => ({
  type: types.SUBMIT_ORDER_CHECKOUT_SUCCESS
})

export const submitOrderCheckoutFailure = (): SubmitOrderCheckoutFailureAction => ({
  type: types.SUBMIT_ORDER_CHECKOUT_FAILURE
})

export const updateTuPulledWithin14Days = (): UpdateTuPulledWithin14DaysAction => ({
  type: types.UPDATE_TU_PULLED_WITHIN_14_DAYS
})

export const updateTuPulledWithin14DaysSuccess = (
  tuPulledWithin14Days: boolean
): UpdateTuPulledWithin14DaysSuccessAction => ({
  type: types.UPDATE_TU_PULLED_WITHIN_14_DAYS_SUCCESS,
  payload: { tuPulledWithin14Days }
})

export const createMerchantCreditLine = (): CreateMerchantCreditLineAction => ({
  type: types.CREATE_MERCHANT_CREDIT_LINE
})

export const updateHasApplied = (hasApplied: boolean): UpdateHasAppliedAction => ({
  type: types.UPDATE_HAS_APPLIED,
  payload: { hasApplied }
})
