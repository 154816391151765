import { updateProgressAction } from '@src/actions/progressAction'
import { InitialState } from '@src/types/store/progress'
import { useEffect } from 'react'
import { Dispatch } from 'redux'

interface Props {
  dispatch: Dispatch<any>
  progress: InitialState
}

export const useProgress = ({ dispatch, progress }: Props) => {
  useEffect(() => {
    dispatch(updateProgressAction(progress))
  }, [])
}
