import { GTM_ACTION, GTM_EVENT } from '@src/lib/constant'

export interface PageEventProps {
  event: GTM_EVENT
  action: GTM_ACTION
  label?: string
}

export const trackEvent = (event, action, label = '') => {
  if (event == null || action == null) {
    return
  }

  let pageEvent: PageEventProps = {
    event: event,
    action: action
  }

  if (label) {
    pageEvent = { ...pageEvent, label }
  }

  //@ts-ignore
  window && window.dataLayer && window.dataLayer.push(pageEvent)
  return pageEvent
}
