import { InitialState, ProgressActionTypes } from '@src/types/store/progress'
import { merge } from 'lodash'

export const UPDATE_PROGRESS = 'UPDATE_PROGRESS'

export const INITIAL_STATE: InitialState = {
  title: '',
  currentStep: 1,
  stepNames: []
}

export default function signOTPReducer(
  state: InitialState = INITIAL_STATE,
  action: ProgressActionTypes
): InitialState {
  switch (action.type) {
    case UPDATE_PROGRESS:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
