import { MERCHANT_CREDIT_LINE_STATE, ONBOARD_STATE } from '@src/lib/constant'
import { OrderActionTypes, OrderState } from '@src/types/store/order'
import { merge } from 'lodash'

export const INITAL_ORDER = 'INITAL_ORDER'
export const GET_WORKFLOW = 'GET_WORKFLOW'
export const GET_WORKFLOW_SUCCESS = 'GET_WORKFLOW_SUCCESS'

export const GET_PERSONAL_INFO = 'GET_PERSONAL_INFO'
export const GET_PERSONAL_INFO_SUCCESS = 'GET_PERSONAL_INFO_SUCCESS'
export const UPDATE_ORDER_PERSONAL_INFO = 'UPDATE_ORDER_PERSONAL_INFO'
export const UPDATE_ORDER_PERSONAL_INFO_SUCCESS = 'UPDATE_ORDER_PERSONAL_INFO_SUCCESS'
export const UPDATE_ORDER_PERSONAL_INFO_FAILURE = 'UPDATE_ORDER_PERSONAL_INFO_FAILURE'

export const UPDATE_ORDER_RESIDENTIAL_INFO = 'UPDATE_ORDER_RESIDENTIAL_INFO'
export const UPDATE_ORDER_RESIDENTIAL_INFO_SUCCESS = 'UPDATE_ORDER_RESIDENTIAL_INFO_SUCCESS'
export const UPDATE_ORDER_RESIDENTIAL_INFO_FAILURE = 'UPDATE_ORDER_RESIDENTIAL_INFO_FAILURE'

export const UPDATE_ORDER_REVIEW_INFO = 'UPDATE_ORDER_REVIEW_INFO'
export const UPDATE_ORDER_REVIEW_INFO_SUCCESS = 'UPDATE_ORDER_REVIEW_INFO_SUCCESS'
export const UPDATE_ORDER_REVIEW_INFO_FAILURE = 'UPDATE_ORDER_REVIEW_INFO_FAILURE'

export const POLL_WORK_FLOW = 'POLL_WORK_FLOW'
export const POLL_WORK_FLOW_STOP = 'POLL_WORK_FLOW_STOP'

export const APPLY_ORDER = 'APPLY_ORDER'
export const APPLY_ORDER_SUCCESS = 'APPLY_ORDER_SUCCESS'
export const APPLY_ORDER_FAILURE = 'APPLY_ORDER_FAILURE'

export const UPDATE_ORDER_PAYMENT_ACCOUNT = 'UPDATE_ORDER_PAYMENT_ACCOUNT'
export const UPDATE_ORDER_PAYMENT_ACCOUNT_SUCCESS = 'UPDATE_ORDER_PAYMENT_ACCOUNT_SUCCESS'
export const UPDATE_ORDER_PAYMENT_ACCOUNT_FAILURE = 'UPDATE_ORDER_PAYMENT_ACCOUNT_FAILURE'

export const TRIGGER_TENCENTS_TEST = 'TRIGGER_TENCENTS_TEST'
export const TRIGGER_TENCENTS_TEST_SUCCESS = 'TRIGGER_TENCENTS_TEST_SUCCESS'
export const TRIGGER_TENCENTS_TEST_FAILURE = 'TRIGGER_TENCENTS_TEST_FAILURE'

export const GET_ORDER_INFO = 'GET_ORDER_INFO'
export const GET_ORDER_INFO_SUCCESS = 'GET_ORDER_INFO_SUCCESS'
export const UPDATE_ORDER_ID = 'UPDATE_ORDER_ID'

export const GET_ORDER_AGREEMENT = 'GET_ORDER_AGREEMENT'
export const GET_ORDER_AGREEMENT_SUCCESS = 'GET_ORDER_AGREEMENT_SUCCESS'

export const SET_AGREEMENT_AUDIO_LISTENED = 'SET_AGREEMENT_AUDIO_LISTENED'
export const SET_AGREEMENT_AUDIO_LISTENED_SUCCESS = 'SET_AGREEMENT_AUDIO_LISTENED_SUCCESS'
export const SET_AGREEMENT_AUDIO_LISTENED_FAILURE = 'SET_AGREEMENT_AUDIO_LISTENED_FAILURE'

export const SET_AGREEMENT_READ = 'SET_AGREEMENT_READ'

export const GET_DECLARATION_VERSION = 'GET_DECLARATION_VERSION'
export const GET_DECLARATION_VERSION_SUCCESS = 'GET_DECLARATION_VERSION_SUCCESS'
export const GET_DECLARATION_VERSION_FAILURE = 'GET_DECLARATION_VERSION_FAILURE'

export const UPDATE_COMPLETE_BOARD = 'UPDATE_COMPLETE_BOARD'
export const UPDATE_COMPLETE_BOARD_SUCCESS = 'UPDATE_COMPLETE_BOARD_SUCCESS'
export const UPDATE_COMPLETE_BOARD_FAILURE = 'UPDATE_COMPLETE_BOARD_FAILURE'

export const UPDATE_ORDER_ERROR = 'UPDATE_ORDER_ERROR'

export const SUCCESS_REDIRECT = 'SUCCESS_REDIRECT'

export const SUBMIT_ORDER_CHECKOUT = 'SUBMIT_ORDER_CHECKOUT'

export const SUBMIT_ORDER_CHECKOUT_SUCCESS = 'SUBMIT_ORDER_CHECKOUT_SUCCESS'
export const SUBMIT_ORDER_CHECKOUT_FAILURE = 'SUBMIT_ORDER_CHECKOUT_FAILURE'

export const UPDATE_TU_PULLED_WITHIN_14_DAYS = 'UPDATE_TU_PULLED_WITHIN_14_DAYS'
export const UPDATE_TU_PULLED_WITHIN_14_DAYS_SUCCESS = 'UPDATE_TU_PULLED_WITHIN_14_DAYS_SUCCESS'

export const CREATE_MERCHANT_CREDIT_LINE = 'CREATE_MERCHANT_CREDIT_LINE'

export const UPDATE_HAS_APPLIED = 'UPDATE_HAS_APPLIED'

export const INITIAL_STATE: OrderState = {
  state: '',
  workflow: {
    personalInfoCompleted: false,
    residentialInfoCompleted: false,
    kbaStatus: {
      success: false,
      retry: false,
      processing: false
    },
    hkidStatus: {
      success: false,
      retry: false,
      processing: false
    },
    bankAccountStatus: {
      success: false,
      retry: false,
      processing: false
    },
    onboardState: null,
    existingCustomer: false,
    merchantCreditLine: MERCHANT_CREDIT_LINE_STATE.notExisted,
    onboardFailureReason: []
  },
  form: {
    personalInformation: {
      mobile: '',
      email: '',
      englishFirstName: '',
      englishLastName: '',
      gender: '0',
      birthday: '',
      hkid: '',
      tuPulledWithin14Days: false,
      repaymentBank: '',
      repaymentAccount: '',
      repaymentDate: '',
      postAip: false
    },
    residentialInformation: {
      residentialAddress: '',
      nationality: '',
      businessNature: '',
      monthlyIncomeRange: '',
      monthlyIncomeUpperBound: '0',
      monthlyIncomeLowerBound: '0',
      updateAction: '',
      unit: '',
      floor: '',
      block: '',
      buildingName: '',
      district: '',
      unitFloorBlock: ''
    },
    reviewInformation: {
      objectToReceiveNewsletter: false,
      declarationAgreed: false
    },
    paymentBankInformation: {
      repaymentBank: '',
      repaymentAccount: '',
      repaymentDate: 1
    }
  },
  agreement: {
    agreementUrl: '',
    audioFile: '',
    audioFileEn: '',
    audioFileTc: '',
    agreementRead: false,
    agreementAudioListened: false,
    sddaAgreementUrl: ''
  },
  agreementReady: false,
  repaymentSchedule: [],
  error: '',
  orderReady: false,
  order: {
    orderId: '',
    totalAmount: 0,
    totalAppleCareAmount: 0,
    items: [{}],
    effectiveRate: 0,
    firstInstalmentAmount: 0,
    state: '',
    createdAt: '',
    updatedAt: '',
    apr: 0
  },
  declarationVersion: '0',
  remaining: {
    total: 780,
    remaining: 780,
    apiVersion: ''
  },
  hasApplied: false
}

export default function orderReducer(
  state: OrderState = INITIAL_STATE,
  action: OrderActionTypes
): OrderState {
  switch (action.type) {
    case GET_PERSONAL_INFO_SUCCESS:
    case UPDATE_ORDER_PERSONAL_INFO_SUCCESS:
      return {
        ...merge(state, {
          form: {
            ...state.form,
            personalInformation: {
              ...state.form.personalInformation,
              ...action.payload.personalInformation
            }
          }
        })
      }
    case UPDATE_ORDER_RESIDENTIAL_INFO_SUCCESS:
      return {
        ...merge(state, {
          form: {
            ...state.form,
            residentialInformation: {
              ...state.form.residentialInformation,
              ...action.payload.residentialInformation
            }
          }
        })
      }
    case UPDATE_ORDER_REVIEW_INFO_SUCCESS:
      return {
        ...merge(state, {
          form: {
            ...state.form,
            personalInformation: {
              ...state.form.reviewInformation,
              ...action.payload.userProfile
            },
            residentialInformation: {
              ...state.form.reviewInformation,
              ...action.payload.userProfile
            },
            reviewInformation: {
              ...state.form.reviewInformation,
              ...action.payload.reviewInformation
            }
          }
        })
      }
    case UPDATE_ORDER_PAYMENT_ACCOUNT_SUCCESS:
      return {
        ...merge(state, {
          form: {
            ...state.form,
            paymentBankInformation: {
              ...state.form.paymentBankInformation,
              repaymentBank: action.payload.paymentBankInformation.repaymentBank,
              repaymentAccount: action.payload.paymentBankInformation.repaymentAccount,
              repaymentDate: action.payload.paymentBankInformation.repaymentDate
            }
          }
        })
      }
    case GET_WORKFLOW_SUCCESS:
      return {
        ...merge(state, {
          workflow: {
            ...state.workflow,
            ...action.payload.workflow
          }
        })
      }
    case UPDATE_ORDER_ID:
      return {
        ...merge(state, {
          order: {
            ...state.order,
            ...action.payload.order
          }
        })
      }
    case GET_ORDER_INFO_SUCCESS:
      return {
        ...merge(state, {
          order: {
            ...state.order,
            ...action.payload.order
          },
          orderReady: true
        })
      }
    case GET_ORDER_AGREEMENT_SUCCESS:
      return {
        ...merge(state, {
          agreement: {
            ...state.agreement,
            ...action.payload.agreement
          },
          agreementReady: true
        })
      }
    case SET_AGREEMENT_AUDIO_LISTENED_SUCCESS:
      return {
        ...merge(state, {
          agreement: {
            ...state.agreement,
            agreementAudioListened: true
          }
        })
      }
    case SET_AGREEMENT_READ:
      return {
        ...merge(state, {
          agreement: {
            ...state.agreement,
            agreementRead: true
          }
        })
      }

    case GET_DECLARATION_VERSION_SUCCESS:
      return {
        ...state,
        declarationVersion: action.payload.version
      }
    case UPDATE_COMPLETE_BOARD_SUCCESS:
      return {
        ...merge(state, {
          remaining: action.payload.remaining
        })
      }
    case UPDATE_ORDER_ERROR:
      return {
        ...merge(state, {
          error: action.payload.error
        })
      }
    case UPDATE_TU_PULLED_WITHIN_14_DAYS_SUCCESS:
      return {
        ...merge(state, {
          form: {
            personalInformation: {
              tuPulledWithin14Days: action.payload.tuPulledWithin14Days
            }
          }
        })
      }
    case UPDATE_HAS_APPLIED:
      return {
        ...merge(state, {
          hasApplied: action.payload.hasApplied
        })
      }
    default:
      return state
  }
}
